import React, { createContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import app from 'gatsby-plugin-firebase-v9.0'
import { getAuth, signInAnonymously, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [cartData, setCartData] = useState(null);
  const [userData, setUserData] = useState();
  const [orderData, setOrderData] = useState();
  const [orderError, setOrderError] = useState();

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else {
        signInAnonymously(auth)
          .then(() => {
            console.info('signInAnonymously')
          })
          .catch((error) => console.info(error.code, error.message));
      }
    });
  }, [])

  useEffect(() => {
    if (!user) return
    getData(user)

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.accessToken}` }
    fetch('https://store-v3-backend-dev.ramotion.agency/api/v1/cart', { headers })
      .then(response => {
        if (!response.ok) throw new Error('Oops, we haven\'t got data')
        return response.json();
      })
      .then(data => setCartData(data))
      .catch((error) => console.error('Error:', error));
  }, [user]); //eslint-disable-line

  async function getData(user) {
    const db = getFirestore(app);
    const docRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        addFirestoreUser(user)
      }
    } catch(error) {
        console.log(error)
    }
  }

  const addFirestoreUser = (user) => {
    const db = getFirestore(app);
    const docRef = doc(db, "users", user.uid);
    const data = {
      favorites: {},
      version: 0
    }

    setDoc(docRef, data)
      .then(() => setUserData(data))
      .catch(error => console.log(error))
  }

  const addFavorite = (id, isBundle) => {
    const db = getFirestore(app);
    const docRef = doc(db, "users", user.uid);

    const productData = {
      [id]: {
        resourseId: id,
        type: isBundle ? 'bundle' : 'product' 
      }
    }

    let favorites = userData.favorites;
    let currentFavorites = {}

    if (userData.favorites[id] === undefined) {
      currentFavorites = {...userData.favorites, ...productData}
    } else {
      delete favorites[id]
      currentFavorites = favorites
    }

    const data = {
      favorites: currentFavorites,
      version: userData.version += 1
   };

   updateDoc(docRef, data)
    .then(() => {
        console.log("Update doc");
        setUserData(data)
    })
    .catch(error => {
        console.log(error);
    })
  }

  const logOut = () => {
    const auth = getAuth(app);
    signOut(auth)
      .then(() => console.log('signOut'))
      .catch((error) => console.info(error.code, error.message));
  }

  const editCart = (sku, qty) => {
    if (!user) return

    fetch('https://store-v3-backend-dev.ramotion.agency/api/v1/cart', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.accessToken}`
      },
      body: JSON.stringify({ sku: sku, qty: qty }),
    })
      .then(response => {
        if (!response.ok) throw new Error('Oops, we haven\'t got data')
        return response.json()
      })
      .then(data => {
        setCartData(data);
      });
  }

  const setCheckout = () => {
    if (!user) return

    fetch('https://store-v3-backend-dev.ramotion.agency/api/v1/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.accessToken}`
      },
    })
      .then(response => {
        if (!response.ok) throw new Error('Oops, we haven\'t got data')
        return response.json()
      })
      .then(data => {
        setCartData(data)
        navigate('/checkout')
      })
  }

  const setOrder = (email, id) => {
    if (!user) return
    setOrderError()
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    console.log(isEmail);

    if (isEmail) {
      fetch('https://store-v3-backend-dev.ramotion.agency/api/v1/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.accessToken}`
      },
      body: JSON.stringify({ checkout_id: id, email: email }),
    })
      .then(response => {
        if (!response.ok) throw new Error('Oops, we haven\'t got data')
        return response.json()
      })
      .then(data => {
        setOrderData(data)
      })
    } else {
      setOrderError('Please, enter valid Email!')
    }
  }

  return (
    <AuthContext.Provider value={{ user, logOut, cartData, editCart, setCheckout, addFavorite, userData, orderData, setOrder, orderError }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
