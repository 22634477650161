exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-contentful-category-slug-js": () => import("./../../../src/pages/category/{contentfulCategory.slug}.js" /* webpackChunkName: "component---src-pages-category-contentful-category-slug-js" */),
  "component---src-pages-category-contentful-tag-slug-js": () => import("./../../../src/pages/category/{contentfulTag.slug}.js" /* webpackChunkName: "component---src-pages-category-contentful-tag-slug-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-contentful-product-slug-js": () => import("./../../../src/pages/product/{contentfulProduct.slug}.js" /* webpackChunkName: "component---src-pages-product-contentful-product-slug-js" */),
  "component---src-pages-product-contentful-product-variant-slug-js": () => import("./../../../src/pages/product/{contentfulProductVariant.slug}.js" /* webpackChunkName: "component---src-pages-product-contentful-product-variant-slug-js" */),
  "component---src-templates-bundles-js": () => import("./../../../src/templates/bundles.js" /* webpackChunkName: "component---src-templates-bundles-js" */)
}

