/* Base styles */
import './src/styles/base/global.css';
import './src/styles/base/fonts.css';
import './src/styles/base/typography.css';

/* Components styles */
import './src/styles/components/header.css';
import './src/styles/components/footer.css';
import './src/styles/components/cart-popup.css';
import './src/styles/components/section-title.css';
import './src/styles/components/heading.css';
import './src/styles/components/cards.css';
import './src/styles/components/card.css';
import './src/styles/components/customers.css';
import './src/styles/components/product.css';
import './src/styles/components/faq.css';
import './src/styles/components/testimonials.css';
import './src/styles/components/error.css';
import './src/styles/components/content.css';

import React from "react"
import "firebase/compat/auth"
import AuthProvider from "./src/context/auth"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)